import CookieConsent, {
    getCookieConsentValue,
    Cookies,
} from 'react-cookie-consent';
import './App.css';
import AppRoutes from './Routes';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const TRACKING_ID = 'G-3FC5QHLJ4P';
export default function App() {

    const handleAcceptCookie = () => {
        ReactGA.initialize(TRACKING_ID);
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    const handleDeclineCookie = () => {
        alert("You have declined the cookie consent");
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };
    return (
        <div>
            <AppRoutes />
            <CookieConsent
                visible="byCookieValue"
                overlay="true"
                overlayClasses="bg-slate-700"
                buttonClasses='bg-slate-500 text-white rounded-md px-4 py-2 text-lg font-medium mx-4'
                declineButtonClasses='bg-red-500 text-white rounded-md px-4 py-2 text-lg font-medium mx-4'
                disableButtonStyles="true"
                buttonText="OK"
                enableDeclineButton="true"
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
            >
                Diese Website verwendet ausschließlich Cookies für Analysen und Statistiken.
                Wenn Sie dies nicht möchten klicken Sie bitte auf "Ablehnen". Ansonsten klicken Sie bitte auf "OK".
            </CookieConsent>
        </div>
    );
};


