import NavButton from "./navigation/NavButton";
import { Outlet } from "react-router-dom";
import Footer from './footer';
export default function Layout() {
    return (
        <div className="font-sans">
            {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
            <div className="container mx-auto py-4 via-slate-900 px-4  bg-gradient-to-r from-slate-700 to-slate-700">
                <nav>
                    <div className="flex flex-row">
                        <div className="flex self-end">
                            <NavButton to="/" text="About" />
                            <NavButton to="contact" text="Kontakt" />
                        </div>
                        <div className="flex flex-grow justify-end">
                            <img src="/images/logosmall.png" alt="logo" className="w-20 rounded-md ring-white-800 drop-shadow-xl " />
                        </div>
                    </div>

                </nav>
            </div>
            <hr />

            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <div className="container mx-auto p-6  rounded-md my-4  h-full  shadow-xl container-bg overflow-y-auto">
                <Outlet />
            </div>
            <div className="container mx-auto py-4 px-8 ">
                <Footer />
            </div>
        </div>
    );
}

