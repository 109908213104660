
import { NavLink } from 'react-router-dom';
export default function NavButton(props) {
    let inactiveClasses = "text-gray-200 hover:text-gray-400 border-gray-500 hover:border-gray-400 px-4 py-2";
    let activeClasses = "text-gray-800 rounded-md font-bold border-gray-1000 bg-gray-200 px-4 py-2 shadow-lg ring-2 ring-gray-300";
    return (
        <div className="flex-initial px-2 w-25">
            <NavLink to={props.to} className={({ isActive }) => isActive ? activeClasses : inactiveClasses}>{props.text}</NavLink>
        </div>
    );
}
