import { useState, useEffect } from "react";
import axios from "axios";
export default function Contact() {
    let logo = "images/mail.png";
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [mailClasses, setMailClasses] = useState("hidden");
    const [nameClasses, setNameClasses] = useState("hidden");
    const [buttonClasses, setButtonClasses] = useState("bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline");
    const [infoClasses, setInfoClasses] = useState("hidden");
    const hash = "d3b6b3f0-3b8a-11eb-9b8b-0b3b0b3b0b3b";
    const sendMail = () => {
        if (!errors.name && !errors.email) {
            axios.post("https://service.sig-dev.de/api/mail", {
                name: name,
                email: email,
                message: message,
                hash: hash
            }).then((response) => {
                if (response.data.status === "success") {
                    alert("Nachricht wurde gesendet.");
                    setButtonClasses("bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hidden");
                    setInfoClasses("text-red-500 text-xl italic");
                    resetForm();
                }
            });
        } else {

            alert("Bitte überprüfen Sie Ihre Eingaben.");
        }
    };
    const resetForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    };
    useEffect(() => {
    }, []);

    useEffect(() => {
        // verify valid email
        if (email !== "" && !email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            setMailClasses("block text-red-500");
            setErrors({ ...errors, email: "Bitte geben Sie eine gültige E-Mail Adresse ein." });
        } else {
            setMailClasses("hidden");
            setErrors({ ...errors, email: "" });
        }
    }, [email]);

    useEffect(() => {
        // verify given name        
        if (name !== "" && !name.match(/^[a-zA-Z0-9._ ]+$/)) {
            setNameClasses("block text-red-500");
            setErrors({ ...errors, name: "Bitte geben Sie einen gültigen Namen ein." });
        } else {
            setNameClasses("hidden");
            setErrors({ ...errors, name: "" });
        }
    }, [name]);


    return (
        <div className="grid sm:grid-cols-1 md:grid-cols-3 pt-8">
            <div className="mt-2">
                <img src={logo} alt="Logo" className="md:w-3/4 sm:w-1/4 mx-auto " />
            </div>
            <div className="md:col-span-2 mt-2">
                <h3 className="text-3xl font-bold text-gray-800">Kontakt</h3>
                <p className="text-gray-600">Sie haben Fragen oder Anregungen? Schreiben Sie mir!</p>
                <div className="mt-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="mt-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                Name
                            </label>
                            <input onChange={(e) => setName(e.target.value)} value={name} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Name" name="name" />
                            <span className={nameClasses}>Bitte geben Sie einen validen Namen ein.</span>
                        </div>
                        <div className="mt-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                E-Mail
                            </label>
                            <input onChange={(e) => setEmail(e.target.value)} value={email} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="E-Mail" name="_replyto" />
                            <span className={mailClasses} id="email-error">Bitte geben Sie eine gültige E-Mail Adresse an.</span>
                        </div>
                    </div>
                    <div className="mt-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2 " htmlFor="message">
                            Nachricht
                        </label>
                        <textarea onChange={(e) => { setMessage(e.target.value) }}
                            className="h-40 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" placeholder="Nachricht" name="message" value={message}></textarea>
                    </div>
                    <div className="mt-2">
                    </div>
                    <div className="mt-4">
                        <button onClick={() => sendMail()} className={buttonClasses}>
                            Senden
                        </button>
                        <div className={infoClasses}>
                            Nachricht wurde gesendet.
                        </div>
                    </div>
                </div>



            </div>
        </div>
    );

}
