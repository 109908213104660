import Pill from "./navigation/Pill";
import ProjectWell from "./navigation/ProjectWell";
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
export default function Home() {
    let logo = "images/sg.jpg";
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/' });
    }, []);
    return (
        <>
            <div className="grid sm:grid-cols-1 md:grid-cols-3 pt-8">
                <div className="mt-2">
                    <img src={logo} alt="Logo" className="md:w-3/4 sm:w-1/4 mx-auto rounded-lg shadow-lg" />
                </div>
                <div className="md:col-span-2 mt-2">
                    <h3 className="text-3xl font-bold text-gray-800">Simon Günther</h3>
                    <ul className="mt-4">
                        <Pill text="ReactJS" />
                        <Pill text="Tailwind CSS" />
                        <Pill text="Redux Tookit" />
                        <Pill text="React Native" />
                        <Pill text="JavaScript" />
                        <Pill text="Fullstack" />
                        <Pill text="PHP" />
                        <Pill text="MySQL" />
                        <Pill text="Laravel" />
                        <Pill text="NeoVim" />
                    </ul>

                    <p className="mt-8 text-gray-800  text-justify" >
                        Ich bin Simon,  39-jähriger Softwareentwickler aus Mainz in
                        Rheinhessen. Ich arbeite seit 2007 als Softwareentwickler und Berater bei unterschiedlichen Unternehmen und Projekten verschiedener Größenordnungen.
                        Ich habe mich auf die Entwicklung von Webanwendungen spezialisiert und arbeite dabei mit JavaScript und React im Frontend und PHP im Backend.
                    </p>
                    <hr className="my-4" />
                    <p className="mt-8 text-gray-800  text-justify" >
                        Derzeit arbeite ich festangestellt als Leiter der Produktentwicklung bei der <a href="https://www.sucurema.com/" className="text-blue-500 hover:text-blue-700">Sucurema GmbH</a> in Mainz.
                        <br />
                        Außerdem berate ich als freiberuflicher Softwareentwickler und Consultant Unternehmen bei der Entwicklung von Webanwendungen, Projekten und Abläufen.
                    </p>

                    <h3 className="text-2xl font-bold text-gray-800 mt-8">Projekthistorie:</h3>
                    <ProjectWell
                        date="2019 - heute"
                        company="Sucurema GmbH"
                        title="Leiter der Produktentwicklung"
                        location="Mainz"
                        content={() => {
                            return (
                                <>
                                    <ul className="list-disc ml-10 mt-2">
                                        <li>Entwicklung von Webanwendungen mit ReactJS und Redux (Toolkit)</li>
                                        <li>Entwicklung von Backendanwendungen mit PHP und MySQL</li>
                                        <li>Projektmanagement</li>
                                        <li>Beratung von Kunden</li>
                                    </ul>
                                </>
                            );
                        }}
                    />
                    <ProjectWell
                        date="2016 - 2019"
                        company="Ilum:e Informatik AG"
                        title="Consultant"
                        location="Mainz"
                        content={() => {
                            return (
                                <>
                                    <ul className="list-disc ml-10 mt-2">
                                        <li>Externer Berater Lufthansa Industry Solutions/Hamburg Süd Container Management System </li>
                                        <li>Externer Berater und Entwickler bei Sanofi Aventis für  CRM Systeme</li>
                                        <li>Inhouse Entwicklung CRM System</li>
                                    </ul>
                                </>
                            );
                        }}
                    />
                    <ProjectWell
                        date="2015 - 2016"
                        company="DFV Deutsche Familienversicherung AG"
                        title="Referent IT"
                        location="Frankfurt am Main"
                        content={() => {
                            return (
                                <>
                                    <ul className="list-disc ml-10 mt-2">
                                        <li>Entwicklung Webanwendungen mit PHP und MySQL</li>
                                        <li>Technische Betreuung der Serversysteme für den Webauftritt der DFV</li>
                                    </ul>
                                </>
                            );
                        }}
                    />
                    <ProjectWell
                        date="2012 - 2015"
                        company="YUM GmbH"
                        title="Softwareentwickler"
                        location="Frankfurt am Main"
                        content={() => {
                            return (
                                <>
                                    <ul className="list-disc ml-10 mt-2">
                                        <li>Entwicklung von Webanwendungen mit PHP und MySQL diverser deutscher Sportvereine (Eintracht Frankfurt/Deutscher Schützenbund)</li>
                                        <li>Betreuung des Webauftritts des DFB (Typo3)</li>
                                        <li>Entwicklung von Schnittstellen und Plugins für den Webauftritt der Rothenberger AG</li>
                                    </ul>
                                </>
                            );
                        }}
                    />
                    <ProjectWell
                        date="2010 - 2012"
                        company="Interexa AG"
                        title="Softwareentwickler"
                        location="Mainz"
                        content={() => {
                            return (
                                <>
                                    <ul className="list-disc ml-10 mt-2">
                                        <li>Entwicklung von Risikomanagementsystemen für Banken mit PHP und MySQL</li>
                                        <li>Kundenbetreuer der Nord LB und Banque Cantonale Vaudoise</li>
                                    </ul>
                                </>
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}
