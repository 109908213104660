import About from "./components/about";
import Impressum from './components/impressum';
import Contact from './components/contact';
import Datenschutz from "./components/datenschutz";
import DatenschutzMT from "./components/datenschutzmt";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
// import Home from "./components/home";
export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<About />} />
                <Route path="about" element={<About />} />
                <Route path="impressum" element={<Impressum />} />
                <Route path="datenschutz" element={<Datenschutz />} />
                <Route path="datenschutzmt" element={<DatenschutzMT />} />
                <Route path="contact" element={<Contact />} />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    )
}


function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
            </p>
        </div>
    );
}
