import { Link } from 'react-router-dom';
export default function FooterItem(props) {
    let classes = "md:flex-initial mr-2 pr-2 flex justify-center";
    !props.last && (classes += " md:border-r-2");
    return (
        <div className={classes}>
            <p className="text-gray-100 text-sm  ">
                {props.to ? <Link to={props.to} className="underline">{props.text}</Link> : props.text}
            </p>
        </div>
    );
}
