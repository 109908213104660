import FooterItem from './navigation/FooterItem';
export default function Footer(props) {
    let built = (<span>© 2023, Built with <a href="https://reactjs.org/" className="text-blue-500 hover:text-blue-800">React</a> and <a href="https://tailwindcss.com/" className="text-blue-500 hover:text-blue-800">Tailwind CSS</a></span>);
    let github = (<span> Visit me on <a href="https://github.com/sigcode" target="_blank" className="text-blue-500 hover:text-blue-800">GitHub</a></span >)
        ;
    return (
        <div className="sm:grid md:flex sm:grid-cols-1 sm:justify-center sm:justify-items-center">
            <FooterItem to="/Impressum" text="Impressum" />
            <FooterItem to="/Datenschutz" text="Datenschutz" />
            <FooterItem text={github} />
            <FooterItem text={built} last="true" />
        </div>
    );
}
