export default function ProjectWell(props) {
    const { date, title, company, location, content } = props;
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 mt-5 ">
            <div className="flex flex-row">
                <div className="flex flex-col">
                    <div className="text-gray-600 text-sm underline">{date}</div>
                    <div className="text-gray-800 text-lg font-bold">{title}</div>
                    <div className="text-gray-600 text-sm font-bold">{company}</div>
                </div>
                <div className="flex-grow"></div>
                <div className="flex flex-col">
                    <div className="text-gray-600 text-sm italic">{location}</div>
                </div>
            </div>
            <div className="flex flex-row">
                <div className="flex flex-col">
                    <div className="text-gray-600 text-sm">{content()}</div>
                </div>
            </div>
        </div>
    );
}

